html,
body {
  font-size: 17px;
}

body {
  margin: 0;
  font-family: "Helvetica", sans-serif, -apple-system, BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #abd1c6;
  color: #0f3433;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
